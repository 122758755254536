import {camelCase, get, isEmpty, isNull, reduce, split} from 'lodash';

/**
 * Returns Object containing Sitecore fields
 *
 * @param {*} props Sitecore Link Field JSON response
 *
 * @returns {object} Containing JSON for Sitecore fields
 */
export const extractFieldsFn = (props) =>
  get(props, 'rendering.fields');
/**
 * Returns the number of elements returned from array
 *
 * @param {*} props Sitecore # of array response
 * @param {*} cols Sitecore # of cols
 * @returns {string} Element width in columns (1/12)
 */
export const columnWidth = (props, cols) =>
  [1, 2, 3, 4].indexOf(props) > -1 ? 12 / parseInt(props) : cols;

/**
 * Returns Web content api URL
 *
 * @param {*} url relative Url
 * @param {*} env App environment
 *
 * @returns {string} relative or abosulte mm com url per environment
 */
 export const getMMDomainUrl = (url, env, useMMComHomeDomain = false, previewEnvUnavailable = false, caseSensitive = false) => {
  let returnUrl = url;
  if (!isEmpty(url))
    url = url.slice(0, 1) === '/' ? url : '/' + url;
  if (env === null || typeof env === 'undefined')
    returnUrl = url;
  else if (env === 'prod')
    returnUrl = useMMComHomeDomain ? 'https://www.massmutual.com' : 'https://www.massmutualtrust.com';
  else if (env === 'preview')
    returnUrl = useMMComHomeDomain ? (previewEnvUnavailable ? 'https://www.massmutual.com' 
    : 'https://www2.massmutual.com') : (previewEnvUnavailable ? 'https://www.massmutualtrust.com' 
    : 'https://www2.massmutualtrust.com') ;
  else if (env === 'qa')
    returnUrl = useMMComHomeDomain ? 'https://www-qa.massmutual.com' : 'https://www-qa.massmutualtrust.com';
  else
    returnUrl = useMMComHomeDomain ? 'https://www-dev.massmutual.com' : 'https://www-dev.massmutualtrust.com';

  return caseSensitive ? returnUrl + url : (returnUrl + url).toLowerCase();
};

export const getAppDomain = (appEnv) => {
  if (appEnv === 'prod')
    return 'https://www.massmutualtrust.com';
  else if (appEnv === 'preview')
    return 'https://www2.massmutualtrust.com';
  else if (appEnv === 'qa')
    return 'https://www-qa.massmutualtrust.com';
  else
    return 'https://www-dev.massmutualtrust.com'; 
};

